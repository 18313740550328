<template>
  <Prism :language="lang">
    <slot />
  </Prism>
</template>

<script>
import 'prismjs'
import 'prismjs/themes/prism-okaidia.css'

import Prism from 'vue-prism-component'

export default {
  name: 'RaCode',

  components: {
    Prism
  },

  props: {
    lang: {
      type: String,
      default: () => 'javascript'
    }
  }
}
</script>
