import Fingerprint2 from 'fingerprintjs2'
import axios from 'axios'

export default {
  data: () => ({
    userId: '',
    vote: false
  }),

  methods: {
    initFingerVote(postId) {
      if (window.requestIdleCallback) {
        requestIdleCallback(() => {
          Fingerprint2.getV18((id) => {
            this.userId = id
            this.renderVote(postId)
          })
        })
      } else {
        setTimeout(() => {
          Fingerprint2.getV18((id) => {
            this.userId = id
            this.renderVote(postId)
          })
        }, 500)
      }
    },

    renderVote(postId) {
      axios.get(`https://api.ralmaz.pro/posts/${postId}`)
        .then((response) => {
          this.vote = {
            up: 0,
            down: 0
          }
          /*
            eslint prefer-destructuring:
            ["error", {VariableDeclarator: {object: true}}]
          */
          this.vote.up = Number(response.data.split(',')[1])
          this.vote.down = Number(response.data.split(',')[2])
        })
        .catch(console.log)
    },

    makeVote(postId, up = true) {
      axios.post(`https://api.ralmaz.pro/posts/${postId}/vote`, {
        user: this.userId,
        payload: Number(up)
      })
        .then((response) => {
          if (response.data === 'success') {
            if (up) {
              this.vote.up += 1
            } else {
              this.vote.down += 1
            }
            this.$toast.open({
              message: 'Thanks for your feedback',
              type: 'info'
            })
          }
          if (response.data === 'voted') {
            this.$toast.open({
              message: 'You have already voted for it',
              type: 'info'
            })
          }
          if (response.data === 'updated') {
            if (up) {
              this.vote.up += 1
              this.vote.down -= 1
            } else {
              this.vote.up -= 1
              this.vote.down += 1
            }
            this.$toast.open({
              message: 'Thanks for your feedback',
              type: 'info'
            })
          }
          if (response.data === 'post not found') {
            this.$toast.open({
              message: 'What you do',
              type: 'error'
            })
          }
        })
        .catch(console.log)
    }
  }
}
